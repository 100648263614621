import {Component, OnInit, OnDestroy} from '@angular/core';
import {RecordService} from '@core/http/record/record.service';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {SelectedProjectService} from '@core/services/selected-project-service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs/internal/Subscription';
import {PageChangedEvent} from 'ngx-bootstrap';

@Component({
  selector: 'app-web-site-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.css']
})
export class SessionsComponent implements OnInit, OnDestroy {

  constructor(private recordService: RecordService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  items = null;
  loading = false;
  itemsPerPage = 50;
  totalItems = 0;
  requestPayload = {
    projectId: '',
    page: 1,
    browser: '',
    os: '',
    device: '',
    country: '',
    city: '',
    sessionName: '',
    tags: {}
  };
  cities = {};
  tags: string[] = [];
  selectedTag = null;
  tagValue = null;

  browsers = {};
  os: string[] = [];
  device: string[] = [];

  private projectId = null;
  private selectedProjectService = new SelectedProjectService();
  private paramsSubscribe: Subscription;

  ngOnInit() {
    this.paramsSubscribe = this.route.queryParams.subscribe(p => {
      const params = Object.assign({}, p);
      const keys = Object.keys(params);

      for (let i = 0; i < keys.length; i++) {
        if (keys[i].startsWith('tag')) {
          const x = keys[i].match(/^tags\[(.+)]$/);
          if (x[1]) {
            if (!params['tags']) {
              params['tags'] = {};
            }
            params['tags'][x[1]] = params[keys[i]];
            delete params[keys[i]];
          }
        }
      }

      this.requestPayload = Object.assign(this.requestPayload, params);

      this.projectId = this.selectedProjectService.getId();
      if (this.projectId) {
        this.requestPayload['projectId'] = this.projectId;
        this.loadFilters();
        this.loadContent();
      }

    });
  }

  ngOnDestroy() {
    this.paramsSubscribe.unsubscribe();
  }

  search() {
    this.requestPayload.page = 1;
    this.action();
  }

  pageChange(e: PageChangedEvent) {
    this.requestPayload.page = e.page;
    this.action();
  }

  action() {
    const params: string = Object.entries(this.requestPayload).map(e => {
      if (e[1] instanceof Object) {
        return Object.keys(e[1]).map(t => `${e[0]}[${t}]=${encodeURIComponent(e[1][t])}`).join('&');
      }
      return `${e[0]}=${encodeURIComponent(e[1])}`;
    }).join('&');
    this.router.navigateByUrl(`/sessions?${params}`, {skipLocationChange: false});
  }

  loadContent() {
    this.loading = true;
    this.recordService.sessions(this.requestPayload)
      .pipe(untilDestroyed(this))
      .subscribe((response: any) => {
        this.items = response.sessions;
        this.loading = false;
        this.totalItems = response.total;
      });
  }

  loadFilters() {
    this.recordService.getFilters(this.projectId)
      .pipe(untilDestroyed(this))
      .subscribe((response: any) => {
        this.cities = [];
        for (let i = 0; i < response.cities.length; i++) {
          const item = response.cities[i];
          if (!this.cities[item.country]) {
            this.cities[item.country] = [];
          }
          this.cities[item.country].push(item.city);
        }
        this.tags = response.tags;
        this.browsers = response.browser;
        this.os = response.os;
        this.device = response.device;
      });
  }

  getBrowserIcon(browser: string) {
    switch (true) {
      case browser.indexOf('CHROME') !== -1:
        return 'icon-chrome';
      case browser.indexOf('SAFARI') !== -1:
        return 'icon-safari';
      case browser.indexOf('FIREFOX') !== -1:
        return 'icon-firefox';
      case browser.indexOf('MOZILLA') !== -1:
        return 'icon-firefox';
      case browser.indexOf('OPERA') !== -1:
        return 'icon-opera';
      case browser.indexOf('IE') !== -1:
        return 'icon-IE';
      case browser.indexOf('EDGE') !== -1:
        return 'icon-IE';
      case browser.indexOf('BOT') !== -1:
        return 'icon-bug2';
      case browser.indexOf('APPLE') !== -1:
        return 'icon-apple2';
      default:
        console.error('unknown browser icon for ', browser);
    }
  }

  getDeviceIcon(type: string) {
    switch (true) {
      case type.indexOf('WEB') !== -1:
        return 'icon-display';
      case type.indexOf('MOBILE') !== -1:
        return 'icon-mobile';
      case type.indexOf('ROBOT') !== -1:
        return 'icon-bug2';
      default:
        console.error('unknown browser type icon for ', type);
    }
  }

  getOsIcon(os: string) {
    switch (true) {
      case os.startsWith('MAC_OS'):
      case os.indexOf('IOS') > -1:
      case os.indexOf('iOS') > -1:
        return 'icon-apple2';
      case os.startsWith('ANDROID'):
        return 'icon-android';
      case os.startsWith('WINDOWS'):
        return 'icon-windows';
      case os.startsWith('LINUX'):
      case os.startsWith('UBUNTU'):
        return 'icon-tux';
      case os.startsWith('UNKNOWN'):
        return 'icon-bug2';
      default:
        console.error('unknown os icon for ', os);
    }
  }

  parseDomain(url?: string): URL | null {
    if (!url) {
      return null;
    }
    try {
      return new URL(url);
    } catch (e) {
      return null;
    }
  }

  parseTags(tags: any): string {
    let str = '<div class="d-session-tags">';
    const keys = Object.keys(tags);
    for (let i = 0; i < keys.length; i++) {
      str += `<span class="badge badge-light">${keys[i]}: <strong>${tags[keys[i]]}</strong></span><br/>`;
    }
    return str + '</div>';
  }

  selectTag(tag) {
    this.selectedTag = tag;
    if (!this.requestPayload['tags']) {
      this.requestPayload['tags'] = {};
    }
    if (!this.requestPayload.tags[tag]) {
      this.requestPayload.tags[tag] = null;
    }
    this.tagValue = null;
  }

  addToTag(event) {
    if (!this.selectedTag) {
      return;
    }
    if (event.target.value === '') {
      delete this.requestPayload.tags[this.selectedTag];
      return;
    }
    this.requestPayload.tags[this.selectedTag] = event.target.value;
  }

  removeTag(tag) {
    delete this.requestPayload.tags[tag];
    if (this.selectedTag === tag) {
      this.selectedTag = null;
    }
    this.tagValue = null;
  }

  removeFormValue(key) {
    this.requestPayload[key] = '';
  }
}
