import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@core/http';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    public userService: UserService) { }

    private currentUserKey = 'currentUser';

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = localStorage.getItem(this.currentUserKey);

    if (currentUser) {
      const jwt = JSON.parse(currentUser);
      const dt = new Date(jwt.expire);

      if (dt.getTime() > new Date().getTime()) {
        this.userService.setAuthority(true);
        return true;
      }
    }

    localStorage.removeItem(this.currentUserKey);
    this.userService.setAuthority(false);
    this.router.navigate(['/auth/login']);
    return false;
  }

}
