import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// plugins
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomFormsModule } from 'ngx-custom-validators';
import { MomentModule } from 'ngx-moment';
import { OrderModule } from 'ngx-order-pipe';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { QuicklinkModule } from 'ngx-quicklink';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
// components
import { AlertComponent } from '@shared/components/alert/alert.component';
import { TitlebarComponent } from '@shared/components/titlebar/titlebar.component';
import { PaginationComponent } from '@shared/components/pagination/pagination.component';
import { BreadcrumbComponent } from '@shared/components/breadcrumb/breadcrumb.component';
import { MenuComponent } from '@shared/components/menu/menu.component';
// import { MenuHorizontalComponent } from '@shared/components/menu-horizontal/menu-horizontal.component';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { NotfoundComponent } from '@shared/components/notfound/notfound.component';

// directives
import { NohrefDirective } from '@shared/directives/nohref.directive';
import { OverlayLoadingDirective } from '@shared/directives/overlay-loading.directive';
import { TableSortDirective } from '@shared/directives/table-sort.directive';
// pipe
import { TruncatePipe } from './pipes/truncate.pipe';
import {TimeDurationPipe} from './pipes/time-duration.pipe';
import {TimeAgoPipe} from 'time-ago-pipe';
import {NoPermissionComponent} from '@shared/components/no-permission/no-permission.component';
import {TranslateModule} from '@ngx-translate/core';
import {NavbarComponent} from '@shared/components/navbar/navbar.component';
import {FooterComponent} from '@shared/components/footer/footer.component';

@NgModule({
  providers: [
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'tr' },
  ],
  imports: [
    // core
    RouterModule,
    CommonModule,
    FormsModule,
    // plugins
    NgSelectModule,
    CustomFormsModule,
    MomentModule,
    OrderModule,
    UiSwitchModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    CollapseModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    FilterPipeModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    QuicklinkModule,
    LoggerModule.forRoot({
      serverLoggingUrl: '/log',
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
      disableConsoleLogging: true
    }),
    TranslateModule,
  ],
  declarations: [
    // directives
    NohrefDirective,
    OverlayLoadingDirective,
    TableSortDirective,
    // pipes
    TruncatePipe,
    TimeDurationPipe,
    // components
    AlertComponent,
    BreadcrumbComponent,
    MenuComponent,
    // MenuHorizontalComponent,
    PaginationComponent,
    TitlebarComponent,
    ConfirmModalComponent,
    NotfoundComponent,
    NoPermissionComponent,
    TimeAgoPipe,
    NavbarComponent,
    FooterComponent
  ],
  exports: [
    // directives
    NohrefDirective,
    OverlayLoadingDirective,
    TableSortDirective,
    // pipes
    TruncatePipe,
    TimeDurationPipe,
    // components
    AlertComponent,
    BreadcrumbComponent,
    MenuComponent,
    // MenuHorizontalComponent,
    PaginationComponent,
    TitlebarComponent,
    ConfirmModalComponent,
    NotfoundComponent,
    NoPermissionComponent,
    // core
    CommonModule,
    FormsModule,
    // modules
    NgSelectModule,
    CustomFormsModule,
    MomentModule,
    OrderModule,
    UiSwitchModule,
    ModalModule,
    AlertModule,
    PaginationModule,
    TooltipModule,
    BsDatepickerModule,
    TimepickerModule,
    BsDropdownModule,
    PopoverModule,
    CollapseModule,
    NgxPermissionsModule,
    FilterPipeModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    QuicklinkModule,
    TimeAgoPipe,
    NavbarComponent,
    FooterComponent
  ],
  entryComponents: [ConfirmModalComponent]
})
export class SharedModule { }
