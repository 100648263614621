import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { RoutingModule } from './routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from '@shared/shared.module';
import { CoreModule } from '@core/core.module';
import {SessionDirectPlayComponent} from '@modules/session/session-direct-play/session-direct-play.component';
import {SessionModule} from '@modules/session/session.module';


@NgModule({
  declarations: [
    AppComponent,
    SessionDirectPlayComponent,
  ],
  imports: [
    RoutingModule,
    CoreModule,
    SharedModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    SessionModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
