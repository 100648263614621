import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '@core/http';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private userService: UserService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(
      evt => {
        if (evt instanceof HttpResponse) {
          if (evt.status === 401 || evt.body.code === '401') {
            // throw new Error(evt.body.message || 'Token is expired.');
            this.userService.logout();
          }
        }
      },
      error => {
        if (error.status === 401) {
          this.userService.logout();
        }
      }
    ));
  }
}
