import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {UserService} from '@core/http';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable()
export class QueryParamTokenGuard implements CanActivate {
  constructor(
    private router: Router,
    public userService: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const token = state.root.queryParams.token;

    if (!token) {
      return this._error();
    }

    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    // Expire
    if (!decodedToken.exp) {
      return this._error();
    }
    const dt = new Date(decodedToken.exp * 1000);
    if (dt.getTime() < new Date().getTime()) {
      return this._error();
    }

    // Client Id
    if (!decodedToken.client_id) {
      return this._error();
    }

    // Authorities
    if (!decodedToken.authorities || !decodedToken.authorities[0] || 'CLIENT' !== decodedToken.authorities[0]) {
      return this._error();
    }

    const t = new Date();
    t.setSeconds(t.getSeconds() + (decodedToken.exp - 60));

    localStorage.setItem('currentUser',
      JSON.stringify({
        clientId: decodedToken.client_id,
        roles: decodedToken.authorities,
        accessToken: token,
        expire: t.toISOString()
      })
    );
    this.userService.setAuthority(true);
    return true;
  }

  _error() {
    this.userService.setAuthority(false);
    this.router.navigate(['/common/no-permission']);
    return false;
  }

}
