export const environment = {
  production: true,

  stripeApiKey: 'pk_live_5zTezYeqJe9gdNG0o6UjQXVe00nOJAPnH0',
  apiUrl: 'https://api.sessionmirror.com',
  assetsUrl: 'https://static-assets.sessionmirror.com',
  googleRecaptchaKey: '6LcT_FAaAAAAAHVdbn_Q86jNnleXs_NCBL_KT0YK',

  modules: {
    sessions: true,
    heatmaps: false,
  },

  supportedLanguages: ['tr', 'en'],
};
