import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {
  IResponseSession,
  IResponseWebSite,
  VideoEvent
} from '@core/http/record/record.interface';
import {AlertService} from '@shared/services/alert.service';
import {environment} from '@environments/environment';
import { Cacheable } from 'ts-cacheable';
import {Subject} from 'rxjs/internal/Subject';

export const cacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root'
})
export class RecordService {

  constructor(protected http: HttpClient, public alertService: AlertService) {
  }

  sessions(request: any): Observable<IResponseSession> {
    const url = environment.apiUrl + '/v1/session/list';
    return this.http.post<IResponseSession>(url, request).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }

  @Cacheable({
    cacheBusterObserver: cacheBuster$
  })
  getFilters(projectId: string): Observable<IResponseSession> {
    const url = `${environment.apiUrl}/v1/session/filters/${projectId}`;
    return this.http.get<any>(url).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }

  records(sessionId: string): Observable<any[]> {
    const url = environment.apiUrl + '/v1/session/record/list';
    return this.http.post<IResponseSession>(url, {sessionId: sessionId}).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'].records;
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }

      })
    );
  }

  downloadRecordData(url: string): Observable<IResponseSession> {
    return this.http.get<IResponseSession>(url);
  }

  record(pageId: string): Observable<Array<VideoEvent>> {
    const url = environment.apiUrl + '/v1/session/record/detail';
    return this.http.post<Array<VideoEvent>>(url, {pageId: pageId}).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          return { data: [] };
        }
      })
    );
  }

  @Cacheable({
    cacheBusterObserver: cacheBuster$
  })
  projects(): Observable<Array<IResponseWebSite>> {
    const url = environment.apiUrl + '/v1/user/projects/list';
    return this.http.post<IResponseWebSite>(url, {}).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'].projects;
        } else {
         return [];
        }
      })
    );
  }

  createProject(request: any): Observable<any> {
    const url = environment.apiUrl + '/v1/user/projects/create';
    return this.http.post<any>(url, request).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }

      })
    );
  }

  updateProject(request: any): Observable<any> {
    const url = environment.apiUrl + '/v1/user/projects/update';
    return this.http.post<any>(url, request).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }

  heatmaps(payload): Observable<Array<any>> {
    const url = environment.apiUrl + '/v1/heatmaps/list';
    return this.http.post<any>(url, payload).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }

      })
    );
  }

  deleteHeatmap(id): Observable<any> {
    const url = environment.apiUrl + '/v1/heatmaps/delete';
    return this.http.post<any>(url, {id: id}).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }

      })
    );
  }

  detailHeatmap(id): Observable<any> {
    const url = environment.apiUrl + '/v1/heatmaps/detail';
    return this.http.post<any>(url, {id: id}).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }

      })
    );
  }

  createHeatmap(params: any): Observable<any> {
    const url = environment.apiUrl + '/v1/heatmaps/create';
    return this.http.post<any>(url, params).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }


}
