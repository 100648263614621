import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RecordService, UserService} from '@core/http';
import {ThemeService} from '@shared/services/theme.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {SelectedProjectService} from '@core/services/selected-project-service';
import {environment} from '@environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {StatisticsService} from '@core/http/statistics/statistics.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {

  private DEFAULT_LANGUAGE = 'en';

  constructor(
    public userService: UserService,
    public themeService: ThemeService,
    private router: Router,
    private recordService: RecordService,
    private translate: TranslateService,
    private statisticsService: StatisticsService
  ) {

    translate.setDefaultLang(this.DEFAULT_LANGUAGE);
    translate.use(localStorage.getItem('lang') || this.DEFAULT_LANGUAGE);
  }

  user = '';
  boxedLayout: boolean;
  menuToggle: boolean;
  mobileToggle: boolean;
  userMenuToggle: boolean;
  isAuthorized: boolean;
  items = null;
  showSites = false;
  selectedDomain: BehaviorSubject<any> = new BehaviorSubject({});
  private selectedProjectService = new SelectedProjectService();

  env = environment;

  private newsList = [];

  ngOnInit() {
    this.userService.isAuthorized$.subscribe(isAuthorized => {
      this.isAuthorized = isAuthorized;
      if (this.isAuthorized) {
        const token = this.userService.getToken();
        if (token) {
          this.user = token.clientId;
        }
      }
      this.getProjects();
    });

    // this.themeService.boxedLayout$.subscribe(response => this.boxedLayout = response);
    // this.themeService.menuToggle$.subscribe(response => this.menuToggle = response);
    // this.themeService.mobileToggle$.subscribe(response => this.mobileToggle = response);
  }

  getProjects() {
    if (!this.isAuthorized) {
      return;
    }
    this.recordService.projects().subscribe(response => {
      this.items = response;
      if (this.items == null || this.items.length === 0) {
        this.selectedProjectService.delete();
      }
      this.setSelectedDomain();
    });
  }

  logout() {
    this.userService.logout();
  }

  selectDomain(site: any) {
    this.selectedProjectService.set(site);
    setTimeout(() => this.showSites = false, 1);
    this.selectedDomain.next(site);
    window.location.href = '/dashboard';
  }

  private setSelectedDomain() {
    const site = this.selectedProjectService.getItem();
    if (site && this.items.filter(x => x.id === site.id).length) {
      this.selectedDomain.next(site);
    } else {
      this.selectedProjectService.delete();
      this.selectedDomain.next(this.items && this.items[0] ? this.items[0] : {});
      this.selectedProjectService.set(this.selectedDomain.getValue());
    }
  }

  changeLanguage(lang) {
    localStorage.setItem('lang', lang);
    this.userService.changeLanguage(lang)
      .subscribe(x => window.location.reload(true));
  }

  getCurrentLanguage() {
    let lang = localStorage.getItem('lang');
    if (!lang) {
      lang = this.DEFAULT_LANGUAGE;
      localStorage.setItem('lang', lang);
    }
    return lang;
  }

  loadNews() {
    if (this.newsList.length) {
      return;
    }
    this.statisticsService.news(1)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.newsList = response.response.news;
      });
  }

  getNewsIcon(type, group) {
    let icon = 'check-circle.svg';
    switch (group) {
      case 'PAYMENT':
        icon = 'check-circle.svg';
    }
    return '/assets/cork/assets/img/icons/' + icon;
  }

  getNewsTitle(news) {
    if (news.group === 'PAYMENT' && news.type === 'PERSONAL') {

      const regex = /(\d{4})\-(\w+)/gm;
      const r = regex.exec(news.title);
      if (r && r[1] && r[2]) {
        return 'monthly.free.sessions';
      }

      return news.title;
    }
  }

  isActiveRoute(url: string): boolean {
    return this.router.isActive(url, false);
  }

  showHideMobileMenu() {
    (window as any).jQuery('body').toggleClass('topbar-closed');
    (window as any).jQuery('body').toggleClass('sbar-open');
    (window as any).jQuery('.overlay').toggleClass('show');
    (window as any).jQuery('html,body').toggleClass('sidebar-noneoverflow');
  }

  ngOnDestroy(): void {
    // close mobile menu
    if ((window as any).jQuery('body').hasClass('topbar-closed')) {
      this.showHideMobileMenu();
    }
  }

}
