import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  onRouteChange$: BehaviorSubject<ActivatedRouteSnapshot>;

  constructor(
    @Inject('routeData') public routeData,
    private titleService: Title) {
    this.onRouteChange$ = new BehaviorSubject(null);
  }

  setRoute(route: ActivatedRouteSnapshot) {
    this.onRouteChange$.next(route);
    if (route && route.data && route.data.config && this.routeData[route.data.config]) {
      this.titleService.setTitle(this.routeData[route.data.config].title);
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route && route) {
      this.setRoute(route);
    }
    return true;
  }
}
