import {Component, OnInit, OnDestroy} from '@angular/core';
import {SelectedProjectService} from '@core/services/selected-project-service';
import {BsModalRef} from 'ngx-bootstrap';

@Component({
  selector: 'app-tracking-code',
  templateUrl: './project-tracking-code.component.html',
  styles: [
    '.hljs-params {color: #1b1b18}'
  ]
})
export class ProjectTrackingCodeComponent implements OnInit, OnDestroy {

  constructor(public bsModalRef: BsModalRef) {}

  private selectedProjectService = new SelectedProjectService();

  projectId = null;

  ngOnInit() {
    this.bsModalRef.setClass('modal-lg');
    if (!this.projectId) {
      this.projectId = this.selectedProjectService.getId();
    }
  }

  ngOnDestroy() { }

}
