
export class SelectedProjectService {

  private key = 'selectedProject';

  set(site: object) {
    localStorage.setItem(this.key, JSON.stringify(site));
  }

  getId() {
    return this.getItem() ? this.getItem().id : null;
  }

  getName() {
    return this.getItem() ? this.getItem().name : null;
  }

  getItem() {
    return JSON.parse(localStorage.getItem(this.key));
  }

  delete() {
    localStorage.removeItem(this.key);
  }

}
