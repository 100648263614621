import { Component, OnInit, OnDestroy } from '@angular/core';
import { ThemeService } from '@shared/services/theme.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {

  constructor(public themeService: ThemeService) {
    this.themeService.boxedLayout$.subscribe(response => this.boxedLayout = response);
  }

  footerToggle: boolean;
  boxedLayout: boolean;

  ngOnInit() { }

  ngOnDestroy() { }
}
