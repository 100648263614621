import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { RoutingModule } from './routing.module';

import {SessionWatchComponent} from './session-watch/session-watch.component';
import {VideoPlayerComponent} from './video-player/video-player.component';
import {SessionsComponent} from './sessions/sessions.component';
import {TranslateModule} from '@ngx-translate/core';
import {TimezonePickerModule} from 'ng2-timezone-selector';

@NgModule({
  declarations: [
    SessionWatchComponent,
    VideoPlayerComponent,
    SessionsComponent,
  ],
  imports: [
    SharedModule,
    RoutingModule,
    TranslateModule,
    TimezonePickerModule
  ],
  exports: [
    VideoPlayerComponent
  ],
  entryComponents: []
})
export class SessionModule { }
