import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RouteService } from '@shared/services/route.service';
import { NgxPermissionsGuard } from 'ngx-permissions';

import {SessionWatchComponent} from './session-watch/session-watch.component';
import {SessionsComponent} from './sessions/sessions.component';

const routes: Routes = [
  {
    path: '',
    component: SessionsComponent,
    canActivate: [RouteService, NgxPermissionsGuard],
    data: {
      config: 'sessions'
    }
  },
  {
    path: ':sessionId/watch/:pageId',
    component: SessionWatchComponent,
    canActivate: [RouteService, NgxPermissionsGuard],
    data: {
      config: 'session'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoutingModule { }

