import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, args1: string, args2: string): string {
    const limit = args1 ? parseInt(args1, 10) : 10;
    const trail = args2 ? args2 : '...';
    return value.length > limit ? value.substring(0, limit) + trail : value;
  }

}
