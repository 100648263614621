import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {AlertService} from '@shared/services/alert.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService implements ErrorHandler {
  constructor(public alertService: AlertService,
              private logger: NGXLogger,
              private translateService: TranslateService) {
  }

  handleError(error: Error | HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      if (!navigator.onLine) {
        return this.alertService.setNotice('No internet connection', 'warning');
      }

      if (error.status === 401) {
        return;
      }

      if (error.error && error.error.status) {
        if (error.error.status !== 200) {
          return this.alertService.setNotice(
            error.error.error.message + ' ' + error.error.error.response,
            'warning'
          );
        }
      }

      if (error.error && typeof error.error === 'string') {
        return this.alertService.setNotice(error.error, 'warning');
      } else if (error.error && error.error.message) {
        if (typeof error.error.response === 'string') {

          this.translateService.get(error.error.message)
            .subscribe(x => {
              this.alertService.setNotice(x + ' ' + error.error.response, 'warning');
            });

          return;
        } else {
          return this.alertService.setNotice(error.error.message, 'warning');
        }
      }
    }

    console.error(error);
    // general error
    // this.alertService.setNotice(error.message || '', 'warning');
  }
}
