import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {RecordService} from '@core/http/record/record.service';
import {TreeMirror} from '@core/services/tree-mirror';
import {IResponseRecords} from '@core/http/record/record.interface';
import {environment} from '@environments/environment';

export enum VideoStatus {
  STOP,
  PLAY,
  PAUSE
}

@Component({
  selector: 'app-session-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.css']
})
export class VideoPlayerComponent implements OnInit, OnDestroy {

  constructor(private recordService: RecordService) {
  }

  @Input()
  record: Subject<any>;

  @Output()
  videoOutput = new Subject<any>();

  public recordDetail: IResponseRecords = null;
  public events = [];
  public videoStatus = VideoStatus.STOP;

  public videoSecond = 0.0;
  public videoTimeDuration = 0;
  public speed = this.getDefaultVideoSpeed();

  private mirror: TreeMirror;
  private cursor: HTMLObjectElement = null;
  private baseUrl = '';

  private pointerIcon = 'icon-pointer';
  private videoPlayerSize = {
    x: 0,
    y: 0,
    scale: 1
  };
  private interval;
  private iframeScroll = 0;
  private consoleErrors = [];
  private consoleDetailModalOpen = false;

  ngOnInit() {
    this.setControllerSize();

    this.record.pipe(untilDestroyed(this)).subscribe(response => {
      this.recordDetail = response;
      this.reset();
      this.parseDomain(response.page);
      this.loading(true);

      this.recordService.record(response.pageId).pipe(untilDestroyed(this))
        .subscribe((res: any) => {
          const events = res.data;
          let _videoTimeDuration = '0.0';

          events.forEach((event) => {
            event.a = event.a.toFixed(2);

            // const second = parseFloat(event.a).toFixed(1).split('.')[0];
            const second = event.a.split('.')[0];

            if (!this.events[second]) {
              this.events[second] = [];
            }

            this.events[second].push({'a': event.a, 'b': event.b, 'c': event.c});
            _videoTimeDuration = event.a;
          });

          // set video time duration
          this.videoTimeDuration = parseFloat(_videoTimeDuration);

          // html init
          this.createMirror();

          // loading
          this.loading(false);

          // play the video
          this.play();

          // analyze video events
          setTimeout(() => this.analyzeEventsForTimeline(), 100);

        }, (e) => {
          this.loading(false);
        });
    });

    // window.addEventListener('resize', (e) => this.resizeEventListener(e), false);
  }

  private resizeEventListener(e: any) {
    this.setControllerSize();
    this.resizeIframe(e.target.innerWidth, e.target.innerHeight);
  }

  ngOnDestroy(): void {
    this.mirror = null;
    this.clearInterval();
    // window.removeEventListener('resize', (e) => this.resizeEventListener(e), false);
  }

  actionButton() {
    const playPauseIcon = document.getElementById('play-pause-icon');
    const setIcon = (iconClass) => {
      playPauseIcon.className = `fa ${iconClass} fa-5x fade show`;
    };

    switch (this.videoStatus) {
      case VideoStatus.STOP :
        setIcon('fa-play-circle');
        this.replay();
        break;
      case VideoStatus.PLAY :
        setIcon('fa-pause-circle');
        this.pause();
        break;
      case VideoStatus.PAUSE :
        setIcon('fa-play-circle');
        this.play();
        break;
    }

    setTimeout(() => {
      playPauseIcon.className = playPauseIcon.className.replace('show', '');
    }, 450);
  }

  play() {
    if (this.videoStatus === VideoStatus.STOP) {
      this.timeline();
    }
    this.videoStatus = VideoStatus.PLAY;
  }

  pause() {
    this.videoStatus = VideoStatus.PAUSE;
  }

  stop() {
    this.videoStatus = VideoStatus.STOP;
  }

  replay() {
    this.resetTime();
    this.createIframe();
    this.createMirror();
    this.play();
  }

  changeSpeed() {
    this.speed *= 2;
    if (this.speed > 16) {
      this.speed = 1;
    }
    window.localStorage.setItem('videoDefaultSpeed', this.speed.toString());
    this.clearInterval();
    this.timeline();
  }

  private timeline() {
    let i = 0;
    const timeout = 10;
    const progress = document.getElementById('pace_progress');

    this.interval = setInterval(() => {
      if (this.videoStatus === VideoStatus.PAUSE) {
        return;
      }

      this.getVideoData();

      this.videoSecond += ((timeout / 1000) * this.speed);
      this.videoSecond = parseFloat(this.videoSecond.toFixed(2));

      let width = (this.videoSecond * 100) / this.videoTimeDuration;

      if (width >= 100) {
        width = 100;
        this.clearInterval();
        this.stop();
        this.videoOutput.next({event: 'stop'});
      }

      progress.style.width = width + '%';
      i++;
    }, timeout);
  }

  private getVideoData() {
    const iframe = this.getIframe();
    const second = this.videoSecond.toFixed(1).split('.')[0];
    const events = this.events[second] as Array<any>;

    if (!events || !events.length) {
      return;
    }

    events.filter((x) => {
      const onlyThisSecond = parseFloat(x.a) === this.videoSecond;
      if (!onlyThisSecond && this.videoSecond < 1) {
        return true;
      }
      return onlyThisSecond;
    })
      .forEach(event => {
        switch (event.b) {
          // page dom events
          // case 'a':
          case 'b':
            let el = this.getElement(event.c.b);
            if (!el) {
              const _x = event.c.b.split('/');
              for (let i = 0; i < _x.length; i++) {
                const lastFieldName = _x.pop();
                const currentElement = this.getElement(_x.join('/'));
                if (currentElement) {
                  const justFieldName = lastFieldName.replace(/(\[\d+])/gm, '');
                  const newElement = iframe.contentDocument.createElement(justFieldName);
                  newElement.innerHTML = event.c.a;
                  currentElement.appendChild(newElement);
                  break;
                }
              }
              if (!el) {
                el = this.getElement(event.c.b);
              }
            }
            if (el) {
              el.outerHTML = event.c.a.replace(/{SM_SAU}/g, environment.assetsUrl);
            }
            break;

          // Mouse events
          case 'c':
            this.mouseEvent(event, iframe);
            break;

          // window.error
          case 'd':
            this.consoleErrors.push({
              time: event.a,
              type: `window.error`,
              message: event.c.e,
            });
            break;

          // console error/warn
          case 'e':
            this.consoleErrors.push({
              time: event.a,
              type: `console.${event.c.e}`,
              message: event.c.m,
            });
            break;
        }
      });
  }

  private mouseEvent(event: any, iframe: any) {
    /**
     'click': 1,
     'dblclick': 2,
     'mousemove': 3,
     'scroll': 4,
     'keypress': 5,
     'drag': 6,
     'touchstart': 7,
     'touchend': 8,
     'touchmove': 9,
     'wheel': 10,
     'keyup': 11,
     */
    if (!this.cursor) {
      this.cursor = document.getElementById('video-player-cursor') as HTMLObjectElement;
    }

    switch (event.c.t) {
      case 3:
        this.cursor.style.left = `${event.c.x * this.videoPlayerSize.scale}px`;
        this.cursor.style.top = `${(event.c.y - this.iframeScroll) * this.videoPlayerSize.scale}px`;
        break;

      case 1:
      case 7:
      case 8:
      case 9:
        this.clickEffect(event.c.x, event.c.y - this.iframeScroll);
        break;

      case 2:
        this.clickEffect(event.c.x, event.c.y - this.iframeScroll, 'icon-multitouch');
        break;

      case 4:
        iframe.contentWindow.scrollTo(0, event.c.y);
        this.iframeScroll = event.c.y;
        break;

      case 5:
      case 11:
        const el = this.getElement(event.c.y);
        if (el) {
          el.value = event.c.x;
        } else {
          console.error('input not found', event.c);
        }
        break;

      case 6:
      case 10:
        // TODO : impl. event
        break;

      default:
        // TODO : remainders ?
        console.error(event);
    }
  }

  private createMirror() {
    try {
      const iframe = this.getIframe().contentDocument;
      const init = this.events[0].filter((z: any) => z.b === 'a')[0];
      iframe.documentElement.innerHTML = init.c.a
        .replace(/{SM_SAU}/g, environment.assetsUrl);
    } catch (e) {
      console.error(e);
    }
  }

  private reset() {
    this.resetTime();
    this.videoTimeDuration = 0;
    this.events = [];
    this.stop();
    this.createIframe();
    this.consoleErrors = [];
  }

  private resetTime() {
    this.videoSecond = 0.0;
  }

  private createIframe() {
    const style = `
    pointer-events:none;
    transform-origin: 0px 0px 0px;
    width:${this.recordDetail.pageX}px;
    height:${this.recordDetail.pageY}px;
    border:none;
    padding:10px;
    display: none;`;

    this.getByClassName('video-player-container').innerHTML =
      '<div class="video-player-iframe-parent" style="margin:auto">' +
        '<iframe id="video-player-iframe" style="' + style + '"></iframe>' +
        '<i class="' + this.pointerIcon + '" id="video-player-cursor"></i>' +
        '<i class="fa fa-play-circle fa-5x fade" id="play-pause-icon"></i>' +
      '</div>' +
      '<div class="modal-backdrop video-player-overlay"></div>';

    this.cursor = null;
    this.getByClassName('video-player-overlay').addEventListener('click', () => this.actionButton(), true);
    this.resizeIframe();
  }

  private setControllerSize() {
    const width = this.getByClassName('video-player-controller').clientWidth - 40;
    const progressBar = this.getByClassName('theme_bar_lg');

    progressBar.style.width = `${width}px`;
    document.getElementById('pace_progress').style.maxWidth = `${width}px`;

    progressBar.removeEventListener('click', null);
    progressBar.addEventListener('click', (e) => this.progressBarClickListener(e, width), true);

    this.videoPlayerSize.x = this.getByClassName('video-player').offsetWidth;
    this.videoPlayerSize.y = window.innerHeight - this.calculateMissingHeight();

    const videoPlayerContainer = this.getByClassName('video-player-container');

    videoPlayerContainer.style.width = `${this.videoPlayerSize.x}px`;
    videoPlayerContainer.style.height = `${this.videoPlayerSize.y}px`;
  }

  private resizeIframe(x = null, y = null) {
    const iframe = document.getElementById('video-player-iframe');
    this.videoPlayerSize.scale = Math.min(
      this.videoPlayerSize.x / (x ? x : this.recordDetail.pageX),
      this.videoPlayerSize.y / (y ? y : this.recordDetail.pageY)
    );
    iframe.style.transform = 'scale(' + this.videoPlayerSize.scale + ')';
    iframe.style.display = 'block';

    const iframeParentDiv = this.getByClassName('video-player-iframe-parent') as HTMLElement;
    iframeParentDiv.style.width = `${this.recordDetail.pageX * this.videoPlayerSize.scale}px`;
    iframeParentDiv.style.height = `${this.recordDetail.pageY * this.videoPlayerSize.scale}px`;

    // play / pause icon position
    const playPauseIcon = document.getElementById('play-pause-icon');
    playPauseIcon.style.left = (((this.recordDetail.pageX * this.videoPlayerSize.scale) / 2) - 40) + 'px';
  }

  private progressBarClickListener(event: any, totalSize: number) {
    this.stop();
    this.clearInterval();
    this.loading(true);
    this.createMirror();
    this.consoleErrors = [];

    const width = ((event.clientX - 40) * 100) / totalSize;
    const _videoSecond = (this.videoTimeDuration / 100) * width;

    this.events.forEach((x: any[]) => {
      for (let i = 0; i < x.length; i++) {
        const t = parseFloat(x[i].a);
        if (t < _videoSecond) {
          this.videoSecond = t;
          this.getVideoData();
        } else {
          break;
        }
      }
    });

    this.videoSecond = _videoSecond;
    this.loading(false);
    this.play();
  }

  private parseDomain(page: string) {
    const parser = document.createElement('a');
    parser.style.display = 'none';
    parser.href = page;

    this.baseUrl = parser.protocol + '//' + parser.host;
    parser.remove();
  }

  private clickEffect(x, y, icon = 'icon-touch') {
    this.cursor.className = icon;

    const span = document.createElement('span');
    span.className = 'video-player-cursor-click-effect';
    span.style.left = `${x * this.videoPlayerSize.scale}px`;
    span.style.top = `${y * this.videoPlayerSize.scale}px`;

    try {
      this.getByClassName('video-player-iframe-parent').appendChild(span);
    } catch (e) {
      console.error('appendChild(span) exception', e);
    }

    setTimeout(() => {
      span.remove();
      if (this.cursor) {
        this.cursor.className = this.pointerIcon;
      }
    }, 1000);
  }

  private getByClassName(className: string): HTMLElement {
    return document.getElementsByClassName(className)[0] as HTMLElement;
  }

  private getIframe(): any {
    return document.getElementById('video-player-iframe');
  }

  private clearInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  private getDefaultVideoSpeed() {
    const speed = window.localStorage.getItem('videoDefaultSpeed');
    if (speed) {
      return parseInt(speed, 0);
    }
    return 1;
  }

  private loading(enable: boolean) {
    const el = this.getByClassName('video-player-overlay');

    if (enable) {
      el.className = 'modal-backdrop video-player-overlay loading';
      el.innerHTML = '<i class="fa fa-spinner fa-pulse"></i>';
    } else {
      el.className = 'modal-backdrop video-player-overlay';
      el.innerHTML = '';
    }

  }

  private analyzeEventsForTimeline() {
    let lastSecond = 0;
    const silence = [];

    const c0 = document.getElementsByClassName('pb-container')[0];
    const progressBar = document.getElementById('pace_progress');
    const pw = +progressBar.style.maxWidth.replace('px', '');

    this.events.forEach((x: any, index: number) => {
      if (index > lastSecond + 5) {
        silence.push({x: lastSecond, y: index});
      }
      lastSecond = index;

      // =================================
      x.filter((t: any) => t.b === 'c').forEach(d => {
        const es = document.createElement('span');
        const left = ((+d.a * pw) / this.videoTimeDuration) + 11;

        if (left > pw) {
          return true;
        }

        es.className = 'pb-mouse-event-icon';
        es.style.width = '7px';
        es.style.backgroundColor = 'rgb(249, 84, 84)';
        es.style.height = '7px';
        es.style.display = 'block';
        es.style.position = 'absolute';
        es.style.borderRadius = '3px';
        es.style.top = '24px';
        es.style.zIndex = '4';
        es.style.left = `${left}px`;

        c0.appendChild(es);
      });
      // =================================
    });

    /*
    silence.forEach((s: any) => {
      const start = (s.x * 100) / this.videoTimeDuration;
      const stop = (s.y * 100) / this.videoTimeDuration;
      const sp = document.createElement('span');

      const width = ((pw * stop) / 100) - start;

      sp.className = 'pb-silence-icon';

      sp.style.left = `${start}px`;
      sp.style.width = `${width}px`;
      sp.style.display = 'block';
      sp.style.backgroundColor = '#616060';
      sp.style.position = 'absolute';
      sp.style.height = '6px';
      sp.style.zIndex = '1';

      c0.appendChild(sp);
    });
     */
  }

  public closeConsoleDetail() {
    this.consoleDetailModalOpen = false;
  }

  private getElement(xpath): any {
    const iframe = this.getIframe();
    return iframe.contentDocument.evaluate(
      xpath.toUpperCase(),
      iframe.contentDocument,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
  }

  private calculateMissingHeight() {
    const playerHeight = this.getByClassName('video-player-controller').offsetHeight;
    let navbarHeight = 0;

    try {
      navbarHeight = this.getByClassName('navbar').offsetHeight;
    } catch (e) {
      //
    }

    const tvdHeight = this.getByClassName('table-video-data').offsetHeight;
    return playerHeight + navbarHeight + tvdHeight + 46;
  }
}
