import {Component, OnInit, OnDestroy} from '@angular/core';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {RecordService} from '@core/http/record/record.service';
import {ActivatedRoute, Router} from '@angular/router';
import {IResponseRecords} from '@core/http/record/record.interface';
import {Subject} from 'rxjs/internal/Subject';
import {ThemeService} from '@shared/services/theme.service';

@Component({
  selector: 'app-session-direct-play',
  templateUrl: './session-direct-play.component.html'
})
export class SessionDirectPlayComponent implements OnInit, OnDestroy {

  constructor(private recordService: RecordService,
              private route: ActivatedRoute,
              public themeService: ThemeService,
              private router: Router) {
  }

  records: Array<IResponseRecords> = [];
  sessionId: string = null;
  pageId: string = null;

  record: IResponseRecords = null;
  componentRecord = new Subject();

  private paramsSubscription;
  private videoLoaded = false;

  ngOnInit() {
    this.themeService.showNavbarAndFooter$.next(false);

    this.paramsSubscription = this.route.params.subscribe(params => {
      this.sessionId = params['sessionId'];
      this.pageId = params['pageId'];
      this.loadRecords();
    });
  }

  loadRecords() {
    this.recordService.records(this.sessionId).pipe(untilDestroyed(this))
      .subscribe((response: any) => {
        this.records = response;
        if (this.records.length) {

          for (const x of response) {
            if (x.pageId === this.pageId) {
              this.record = x;
              this.getVideo();
              break;
            }
          }
        }

        if (!this.record) {
          this.router.navigate(['/common/404']);
        }
      });
  }

  getVideo() {
    if (!this.videoLoaded) {
      this.componentRecord.next(this.record);
    }
  }

  listenVideoPlayerEvent(event) {
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }

}
