export const routeData = {

  'web-sites': {
    title: 'Sessions',
    icon: 'icon-home2'
  },
  'dashboard': {
    title: 'Dashboard',
    icon: 'icon-home2'
  },
  'sessions': {
    title: 'Sessions',
    icon: 'icon-video-camera'
  },
  'heatmaps': {
    title: 'Heatmaps',
    icon: 'icon-fire'
  },
};


