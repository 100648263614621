import {Component, OnInit, OnDestroy} from '@angular/core';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {RecordService} from '@core/http/record/record.service';
import {ActivatedRoute, Router} from '@angular/router';
import {IResponseRecords} from '@core/http/record/record.interface';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'app-session-watch',
  templateUrl: './session-watch.component.html'
})
export class SessionWatchComponent implements OnInit, OnDestroy {

  constructor(private recordService: RecordService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  records: Array<IResponseRecords> = [];
  sessionId: string = null;
  pageId: string = null;

  record: IResponseRecords = null;
  componentRecord = new Subject();
  recordIndex = 0;

  private paramsSubscription;

  ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe(params => {
      this.sessionId = params['sessionId'];
      this.pageId = params['pageId'];
      this.loadRecords();
    });
  }

  loadRecords() {
    this.recordService.records(this.sessionId).pipe(untilDestroyed(this))
      .subscribe((response: any) => {
        this.records = response;
        if (this.records.length) {
          this.recordIndex = this.findRecordIndex();
          this.record = this.records[this.recordIndex];

          if (this.record) {
            this.getVideo();
          }
        }
      });
  }

  changeRecordId(w: string) {
    // Prev
    if (w === 'prev') {
      if (this.records[this.recordIndex - 1]) {
        this.recordIndex = this.recordIndex - 1;
        this.record = this.records[this.recordIndex];
        // this.getVideo();
      }
    }

    // Next
    if (w === 'next') {
      if (this.records[this.recordIndex + 1]) {
        this.recordIndex = this.recordIndex + 1;
        this.record = this.records[this.recordIndex];
        // this.getVideo();
      }
    }

    this.router.navigate([`/sessions/${this.sessionId}/watch/${this.record.pageId}`]);
  }

  getVideo() {
    this.componentRecord.next(this.record);
  }

  listenVideoPlayerEvent(event) {
    if (event.event === 'stop') {
      if (this.records[this.recordIndex + 1]) {
        // this.recordIndex++;
        // this.changeRecordId('next');
      }
    }
  }

  private findRecordIndex() {
    let index = 0;
    for (const r of this.records) {
      if (r.pageId === this.pageId) {
        break;
      }
      index++;
    }
    return index;
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }

}
