import { Component, OnInit } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import { UserService } from '@core/http';
import { ThemeService } from '@shared/services/theme.service';
import {environment} from '@environments/environment';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    public userService: UserService,
    public themeService: ThemeService,
    private ngxPermissionsService: NgxPermissionsService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) { }

  isAuthorized = false;
  boxedLayout: boolean;
  enabledNavbarAndFooter = false;
  private paramsSubscribe;

  ngOnInit() {
    // set default lang
    this.translate.use('en');
    this.paramsSubscribe = this.route.queryParams.subscribe(p => {
      if (p.lang && environment.supportedLanguages.indexOf(p.lang) !== -1) {
        this.translate.use(p.lang);
      }
    });

    this.themeService.boxedLayout$.subscribe(response => this.boxedLayout = response);
    this.themeService.showNavbarAndFooter$.subscribe(response => this.enabledNavbarAndFooter = response);

    this.userService.isAuthorized$.subscribe(status => {
      this.isAuthorized = status;
      if (status) {
        this.ngxPermissionsService.addPermission(this.userService.getToken().roles);
      }
    });

    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

  }
}
