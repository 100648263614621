import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler } from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';

import { SharedModule } from '@shared/shared.module';

import { AuthGuard } from '@core/guards/auth.guard';

import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { UnauthorizedInterceptor } from '@core/interceptors/unauthorized.interceptor';

import { ErrorService } from '@core/services/error.service';

import { routeData } from '@core/configs/route.config';
import { menuData } from '@core/configs/menu.config';
import {ProjectTrackingCodeComponent} from '@modules/dashboard/project-tracking-code/project-tracking-code.component';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {QueryParamTokenGuard} from '@core/guards/query-param-token.guard';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    ProjectTrackingCodeComponent
  ],
  exports: [
    TranslateModule,
  ],
  providers: [
    AuthGuard,
    QueryParamTokenGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorService },
    { provide: 'routeData', useValue: routeData },
    { provide: 'menuData', useValue: menuData }
  ],
  entryComponents: [ProjectTrackingCodeComponent]
})
export class CoreModule { }
