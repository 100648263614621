import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {QuicklinkStrategy} from 'ngx-quicklink';
import {AuthGuard} from '@core/guards/auth.guard';
import {SessionDirectPlayComponent} from '@modules/session/session-direct-play/session-direct-play.component';
import {QueryParamTokenGuard} from '@core/guards/query-param-token.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: './modules/auth/auth.module#AuthModule'
  },
  {
    path: 'dashboard',
    loadChildren: './modules/dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'direct/:sessionId/play/:pageId',
    component: SessionDirectPlayComponent,
    canActivate: [QueryParamTokenGuard],
  },
  {
    path: 'sessions',
    loadChildren: './modules/session/session.module#SessionModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'heatmaps',
    loadChildren: './modules/heatmap/heatmap.module#HeatmapModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'common',
    loadChildren: './modules/common/common.module#CommonModule'
  },
  {
    path: 'user',
    loadChildren: './modules/user/user.module#UserModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'statistics',
    loadChildren: './modules/statistics/statistics.module#StatisticsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'plugins',
    loadChildren: './modules/plugins/plugins.module#PluginsModule',
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'common/404'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy
  })],
  exports: [RouterModule]
})
export class RoutingModule {
}
