import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(protected http: HttpClient) {
  }

  statistics(projectId): Observable<any> {
    const url = environment.apiUrl + '/v1/statistics';
    return this.http.post<any>(url, {projectId: projectId}).pipe(
      map(response => {
        return response;
      })
    );
  }

  monthlyUsage(projectId?: string): Observable<any> {
    const url = environment.apiUrl + '/v1/statistics/monthly-usage';
    const body = projectId ? {projectId: projectId} : {};
    return this.http.post<any>(url, body).pipe(
      map(response => {
        return response;
      })
    );
  }

  news(page: number): Observable<any> {
    const url = environment.apiUrl + '/v1/news/list';
    return this.http.post<any>(url, {page: page}).pipe(
      map(response => {
        return response;
      })
    );
  }


}
