import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgxPermissionsService } from 'ngx-permissions';
import {IReqLogin, IResLogin, IUserBasicStatsResponse} from './user.interface';
import {environment} from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  isAuthorized$: BehaviorSubject<boolean>;

  constructor(
    protected http: HttpClient,
    private ngxPermissionsService: NgxPermissionsService,
    private router: Router
  ) {
    this.isAuthorized$ = new BehaviorSubject(false);

    if (this.getToken()) {
      this.isAuthorized$.next(true);
    }
  }

  register(request: any): Observable<any> {
    const headers = {
      'Content-Type': 'application/json'
    };
    return this.http.post<any>(environment.apiUrl + '/v1/user/register', request, {headers: headers})
      .pipe(map(response => {
        return response;
      })
    );
  }

  login(request: IReqLogin): Observable<IResLogin> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Authorization': 'Basic ' + btoa(request.email + ':' + request.password)
    });
    return this.http.post<IResLogin>(environment.apiUrl + '/oauth/token', null,
      {
        params: { 'scope': '', 'grant_type': 'client_credentials' },
        headers: headers
      }).pipe(
        map(response => {
            const helper = new JwtHelperService();
            const decodedToken = helper.decodeToken(response.access_token);
            const dt = new Date();
            dt.setSeconds(dt.getSeconds() + (response.expires_in - 60));

            localStorage.setItem('currentUser',
              JSON.stringify({
                clientId: decodedToken.client_id,
                roles: decodedToken.authorities,
                accessToken: response.access_token,
                expire: dt.toISOString()
              })
            );
            // this.ngxPermissionsService.addPermission(decodedToken.authorities);
            // this.isAuthorized$.next(true);
            return response;
        })
      );
  }

  logout() {
    this.isAuthorized$.next(false);
    localStorage.removeItem('currentUser');
    this.router.navigate(['/auth/login']);
  }

  setAuthority(status: boolean) {
    this.isAuthorized$.next(status);
  }

  getToken() {
    const _data = localStorage.getItem('currentUser');
    if (_data) {
      return JSON.parse(_data);
    } else {
      return null;
    }
  }

  basicStats(): Observable<IUserBasicStatsResponse> {
    const url = environment.apiUrl + '/v1/user/basic-stats';
    return this.http.get<IUserBasicStatsResponse>(url).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }

      })
    );
  }

  payments(page: number = 0): Observable<Array<any>> {
    const url = environment.apiUrl + '/v1/user/payments';
    const payload = {
      page: page
    };
    return this.http.post<Array<any>>(url, payload).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }

  makePayment(payload): Observable<Array<any>> {
    const url = environment.apiUrl + '/v1/payment/create';
    return this.http.post<Array<any>>(url, payload).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }



  setProjectStatus(payload): Observable<any> {
    const url = environment.apiUrl + '/v1/user/projects/update';
    return this.http.post<any>(url, payload).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }

  changeTimezone(timezone): Observable<any> {
    const url = environment.apiUrl + '/v1/user/settings/timezone';
    return this.http.post<any>(url, {timezone: timezone}).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }

  changeLanguage(language): Observable<any> {
    const url = environment.apiUrl + '/v1/user/settings/language';
    return this.http.post<any>(url, {language: language}).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }

  getAccountDetail(projectId): Observable<any> {
    const url = environment.apiUrl + '/v1/user/settings/account-detail';
    return this.http.post<any>(url, {projectId: projectId}).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }

  addBannedIp(projectId, ip): Observable<any> {
    const url = environment.apiUrl + '/v1/user/settings/add-banned-ip';
    return this.http.post<any>(url, {projectId: projectId, ip: ip}).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }

  removeBannedIp(projectId, ip): Observable<any> {
    const url = environment.apiUrl + '/v1/user/settings/remove-banned-ip';
    return this.http.post<any>(url, {projectId: projectId, ip: ip}).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }

  changeUserPassword(params): Observable<any> {
    const url = environment.apiUrl + '/v1/user/settings/change-password';
    return this.http.post<any>(url, params).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }

  createApiUser(): Observable<any> {
    const url = environment.apiUrl + '/v1/user/settings/create-api-user';
    return this.http.get<any>(url).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }

  deleteApiUser(apiKey: string): Observable<any> {
    const url = environment.apiUrl + '/v1/user/settings/delete-api-user';
    return this.http.post<any>(url, {apiKey: apiKey}).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }

  createSubscription(params: any): Observable<any> {
    const url = environment.apiUrl + '/v1/payment/create-subscription';
    return this.http.post<any>(url, params).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }

  cancelSubscription(transactionId: string): Observable<any> {
    const url = environment.apiUrl + '/v1/payment/cancel-subscription';
    return this.http.post<any>(url, {transactionId: transactionId}).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }

  addDomainPermission(projectId, domain): Observable<any> {
    const url = environment.apiUrl + '/v1/user/settings/add-domain-permission';
    return this.http.post<any>(url, {projectId: projectId, domain: domain}).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }

  removeDomainPermission(projectId, domain): Observable<any> {
    const url = environment.apiUrl + '/v1/user/settings/remove-domain-permission';
    return this.http.post<any>(url, {projectId: projectId, domain: domain}).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }

  forgotPassword(params): Observable<any> {
    const url = environment.apiUrl + '/v1/user/forgot-password';
    return this.http.post<any>(url, params).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }

  resetPassword(params, token): Observable<any> {
    const url = environment.apiUrl + '/v1/user/reset-password';
    return this.http.post<any>(url, params, {headers: {authorization: `Bearer ${token}`}}).pipe(
      map(response => {
        if (response['code'] === 100) {
          return response['response'];
        } else {
          throw new HttpErrorResponse({
            error: response,
            url: url
          });
        }
      })
    );
  }

}
